.desktopLetterThumb {
  display: block;
}
@media screen and (min-width: 1200px) {
  .desktopLetterThumb {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1200px) {
  .letterTitle {
    color: #1d1d1d;

    font-family: "Firs Neue";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 40px */
    text-transform: uppercase;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .letterTitle {
    font-family: "Firs Neue";
    color: #1d1d1d;

    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
}
.letterDescriptionThumb {
  display: flex;
}
@media screen and (min-width: 1200px) {
  .letterDescription {
    width: 330px;
  }
}
@media screen and (max-width: 1200px) {
  .letterDescriptionIcon {
    margin-right: 60px;
  }
}
@media screen and (min-width: 1200px) {
  .letterDescriptionIcon {
    margin-right: 40%;
  }
}
.letterDescriptionTitle {
  color: #1d1d1d;
  font-family: "Firs Neue";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 30.8px */
  margin-bottom: 20px;
}
.letterSubDescription {
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  margin-bottom: 40px;
}
@media screen and (max-width: 1200px) {
  .letterForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 1200px) {
  .letterForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 1200px) {
  .letterInput {
    border-radius: 10px;
    width: 80vw;
    border: 1px solid var(--stroke, #f1f1f1);
    color: #f1f1f1;
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    margin-bottom: 10px;
    padding: 20px 20px;
  }
}
@media screen and (min-width: 1200px) {
  .letterInput {
    border-radius: 10px;
    width: 420px;
    border: 1px solid var(--stroke, #f1f1f1);
    color: #f1f1f1;
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
    margin-bottom: 10px;
    padding: 20px 20px;
  }
}

@media screen and (min-width: 1200px) {
  .formSendBtnThumb {
    display: flex;
    width: 100%;
    /* align-items: flex-start; */
    justify-content: space-between;
  }
}
@media screen and (max-width: 1200px) {
  .letterSendButton {
    display: block;
    background-color: #e53d12;
    text-align: center;
    width: 100%;
    border: none;
    color: #fff;
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    margin-bottom: 10px;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 60px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .letterSendButton {
    border-radius: 10px;
    display: block;
    background-color: #e53d12;
    text-align: center;
    width: 100%;
    border: none;
    color: #fff;
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
    margin-bottom: 10px;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 60px;
  }
}

.letterSendIcon {
  margin-left: 10px;
  vertical-align: text-bottom;
}
@media screen and (max-width: 1200px) {
  .letterFileLabel {
    border-radius: 10px;
    display: block;
    width: 80vw;
    padding: 20px;
    background: var(--grey-bg, #f9f9f9);
    color: #c6c8d0;
    text-align: center;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
  }
}
@media screen and (min-width: 1200px) {
  .letterFileLabel {
    border-radius: 10px;
    display: block;
    margin-top: 9px;
    width: 100%;
    margin-right: 20px;
    height: 24px;
    padding: 20px;
    background: var(--grey-bg, #f9f9f9);
    color: #c6c8d0;
    text-align: center;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
}
.letterAttachFileIcon {
  margin-right: 10px;
  vertical-align: middle;
}
.line {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
