.desktopThumb {
  display: none;
}

@media screen and (max-width: 480px) {
  .sectionTitle {
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 97px;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 480px) and (max-width: 1200px) {
  .sectionTitle {
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 88.403px;
    font-style: normal;
    font-weight: 400;
    line-height: 97px;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .sectionTitle {
    color: var(--txt-black, #1d1d1d);
    font-size: 190px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;

    text-align: center;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .patreonDescription {
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.8px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .patreonDescription {
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.8px;
    margin-bottom: 30px;
    width: 400px;
  }
}
.booksDescriptionThumb {
  display: flex;
}
@media screen and (max-width: 1200px) {
  .patreonBookDescription {
    color: var(--txt-black, #1d1d1d);
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .patreonBookDescription {
    color: var(--txt-black, #1d1d1d);
    font-family: Stolzl;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 30px;
    width: 320px;
    margin-left: 14%;
  }
}
.patreonEmptyBook {
  width: 80vw;
}
.patreonThumb {
  display: flex;
  justify-content: center;
}
.patreonBtn {
  border-radius: 10px;
  border: none;
  width: 90vw;
  background-color: #e53d12;
  height: 60px;
  color: #fff;
  font-family: Stolzl;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  margin-bottom: 40px;
}
.patreonIcon {
  margin-left: 10px;
  vertical-align: text-bottom;
}
.patreonLine {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}

@media screen and (min-width: 1200px) {
  .mobileThumb {
    display: none;
  }

  .desktopThumb {
    display: block;
  }

  .patreonBtn {
    width: 310px;
    margin-right: 20px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .sectionTitle {
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 190px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-align: start;
    margin-bottom: 10px;
  }
}
