/* .heroSection{
  padding: 0 20px;
} */
.heroSectionThumb {
  position: relative;
}
@media screen and (max-width: 480px) {
  .heroImgMobile {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 370px;
    width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .heroImgMobile {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 500px;
    width: 100%;
  }
}

/* @media screen and (min-width: 767px) and (max-width: 1200px) {
  .heroImgMobile {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 90%;
    width: 92vw;
  }
} */
.heroImgDesktop {
  display: none;
}
@media screen and (min-width: 767px) {
  .heroImgMobile {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .heroImgDesktop {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 500px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .heroSignatureIconThumb {
    position: absolute;
    /* top: 54%; */
    transform: translate(-100%, -104%);
    left: 100%;
  }
}
@media screen and (max-width: 767px) {
  .heroSignatureIcon {
    width: 60px;
  }
}
@media screen and (min-width: 768px) {
  .heroSignatureIconThumb {
    position: absolute;
    /* top: 54%; */
    transform: translate(-97%, -110%);
    left: 97%;
  }
}
@media screen and ((min-width: 768px) and (max-width:1199px)) {
  .heroSignatureIcon {
    width: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .heroSignatureIcon {
    width: 100px;
  }
}
@media screen and (max-width: 480px) {
  .heroMobileArrowsThumb {
    display: flex;
    position: absolute;
    transform: translateY(-100%);
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .heroMobileArrowsThumb {
    /* left: 2.5%; */
    display: flex;
    position: absolute;
    transform: translate(0%, -100%);
  }
}
/* @media screen and (min-width: 767px) and (max-width: 1200px) {
  .heroMobileArrowsThumb {
    left: 3%;
    display: flex;
    position: absolute;
    transform: translate(3%, -100%);
  }
} */
@media screen and (min-width: 768px) {
  .heroMobileArrowsThumb {
    display: none;
  }
}
.heroDesktopArrowsThumb {
  display: none;
}
@media screen and (min-width: 768px) {
  .heroDesktopArrowsThumb {
    /* left: 5.3%; */
    display: flex;
    position: absolute;
    transform: translate(0%, -100%);
  }
}
.heroArrowLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #f9f9ff;
}
.heroArrowRight {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #e53d12;
}
@media screen and ((max-width: 479px)) {
  .heroDesktopThumb {
    height: 250px;
    padding: 0 20px;
  }
}
@media screen and ((min-width: 480px) and (max-width: 767px)) {
  .heroDesktopThumb {
    height: 220px;
    padding: 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .heroDesktopThumb {
    height: 200px;
    padding: 0px;
  }
}
@media screen and (min-width: 1200px) {
  .heroDesktopThumb {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .heroDescription {
    margin-top: 30px;
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-transform: uppercase;
    margin-bottom: 20px;
    /* text-align: center; */
  }
}
@media screen and (min-width: 1200px) {
  .heroDescription {
    margin-top: 10px;
    width: 100%;
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100px */
    text-transform: uppercase;
  }
}
@media screen and ((max-width: 479px)) {
  .heroBookList {
    display: flex;
    margin-bottom: 70px;
    margin-left: 0;
  }
}

@media screen and ((min-width: 480px) and (max-width: 1200px)) {
  .heroBookList {
    display: flex;
    margin-bottom: 60px;
    margin-left: 0;
  }
}

@media screen and (min-width: 1200px) {
  .heroBookList {
    display: block;
    margin-left: 50px;
  }
}

.heroBookName {
  color: #e53d12;
  font-family: "Firs Neue";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase;
  margin-right: 30px;
}
.heroBookDescription {
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}
.heroLine {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}
