@media screen and (max-width: 1200px) {
  .sectionTitleThumb {
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .sectionTitle {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 1200px) {
  .sectionTitleThumb {
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .sectionTitle {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 1200px) {
  .authorInfoThumb {
    display: flex;
    align-items: flex-start;
    margin-right: 130px;
  }
}
@media screen and (min-width: 1200px) {
  .authorTextsThumb {
    display: flex;
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .descriptionThumb {
    margin-right: 30px;
    width: 45%;
  }
}

@media screen and (min-width: 1200px) {
  .textsThumb {
    width: 45%;
  }
}
@media screen and (max-width: 1200px) {
  .authorInfoThumb {
    display: flex;
    align-items: flex-start;
  }
}
@media screen and (min-width: 1200px) {
  .authorDetailsThumb {
    width: 40%;
  }
}
@media screen and ((max-width: 479px)) {
  .authorThumb {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-left: -34px;
  }
}
@media screen and ((min-width: 480px) and (max-width: 1200px)) {
  .authorThumb {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-left: -14px;
  }
}
@media screen and (max-width: 1200px) {
  .textsThumb {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .authorDataThumb {
    display: flex;
  }
  .authorThumb {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 40px;
    margin-left: -14px;
  }
}
@media screen and (max-width: 1200px) {
  .authorBirthList {
    transform: rotate(-90deg);
    margin-top: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .authorBirthList {
    margin-top: 20px;
  }
}
.authorBirth {
  color: var(--txt-grey, #c6c8d0);
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.authorDateOfBirth {
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 14px */
}

.authorDescriptionThumb {
  display: flex;
}
@media screen and (max-width: 479px) {
  .authorEmptySpace {
    width: 3vw;
  }
}
@media screen and ((min-width: 480px) and (max-width: 1200px)) {
  .authorEmptySpace {
    width: 3vw;
  }
}

@media screen and (min-width: 1200px) {
  .authorEmptySpace {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .authorPhoto {
    margin-bottom: 37px;
  }

  .authorPhotoDesktop {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .authorPhoto {
    display: none;
  }

  .authorPhotoDesktop {
    margin-left: 20px;
  }
}

.authorName {
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 19.6px */
  text-transform: uppercase;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .descriptionThumb {
    margin-bottom: 20px;
  }
  .authorDescription {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 24.2px */
    margin-bottom: 40px;
  }
  .authorText {
    color: #1d1d1d;
    font-kerning: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
  }
}
.authorText {
  color: #1d1d1d;
  font-kerning: none;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}

@media screen and (min-width: 1200px) {
  .authorDescription {
    /* width: 420px; */
    width: 90%;
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 33px */
    margin-bottom: 40px;
  }
}

.authorDetails {
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  margin-bottom: 60px;
}

.authorMoreIcon {
  margin-left: 10px;
  vertical-align: text-bottom;
}

.line {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}
