@media screen and (max-width: 1200px) {
  .navigation {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1200px) {
  .navigation {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }
}
.navigationDescription {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .navigationTitle {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1200px) {
  .navigationTitle {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
  }
}
.navigationName {
  color: var(--txt-black, #1d1d1d);
  font-family: "Firs Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
}

.navigationLogo {
  margin-right: 12px;
}
.navigationShoppingThumb {
  display: flex;
}
.shoppingBagValueDesktop {
  display: none;
}

@media screen and (min-width: 1200px) {
  .shoppingBagValueDesktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    background: var(--grey-bg, #f9f9f9);
    color: var(--txt-grey, #c6c8d0);
    text-align: center;
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
  }
}

@media screen and (max-width: 1200px) {
  .shoppingBagMobileValue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    background: var(--grey-bg, #f9f9f9);
    color: var(--txt-grey, #c6c8d0);
    text-align: center;
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
  }
}
@media screen and (min-width: 1200px) {
  .shoppingBagMobileValue {
    display: none;
  }
}
.shoppingBagValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background: var(--grey-bg, #f9f9f9);
  color: var(--txt-grey, #c6c8d0);
  text-align: center;
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
}
.shoppingBagIcon {
  margin-bottom: 8px;
}

@media screen and (max-width: 1199px) {
  .mobileMenuBg {
    width: 50px;
    height: 50px;
    background-color: #e53d12;
  }
}
@media screen and (min-width: 1200px) {
  .mobileMenuBg {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  .mobileMenu {
    padding: 20px 15px;
  }
}
@media screen and (max-width: 1199px) {
  .mobileNavHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .mobileNavHeaderOverlay {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 20px;
    padding-top: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .navigationMobileOverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
  }
}
@media screen and (max-width: 1199px) {
  .mobileMenuBgClose {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    background-color: #f9f9f9;
  }
}

@media screen and (max-width: 1199px) {
  .mobileMenuBgCloseLocale {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    background-color: #f9f9f9;
    color: var(--txt-grey, #c6c8d0);
    text-align: center;
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .mobileMenuBgCloseLocale {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    background-color: #f9f9f9;
    color: var(--txt-grey, #c6c8d0);
    text-align: center;
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
}
@media screen and (max-width: 1199px) {
  .navMobileLine {
    width: 335px;
    height: 1px;
    background-color: #000;
    width: 92%;
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1199px) {
  .navMobileLinks {
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 40px;
    text-align: center;
  }
}
@media screen and (max-width: 1199px) {
  .navMobileLink {
    color: #e53d12;
    font-family: "Firs Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 40px;
    text-align: center;
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1199px) {
  .navDesktopLinksRed {
    color: #e53d12;
    font-family: "Firs Neue";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    padding-top: 15px;
    margin-right: 40px;
    padding-top: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .navMobileMediaThumb {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }
}
.navMenuMediaList {
  display: none;
}
@media screen and (min-width: 1200px) {
  .navMenuMediaList {
    display: flex;
  }
}
.navDesktopMediaThumb {
  display: none;
}
@media screen and (min-width: 1200px) {
  .navDesktopMediaThumb {
    display: flex;
    justify-content: center;
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 1199px) {
  .mobileMenuMedia {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #f9f9f9;
    margin-right: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .mobileMenuMedia {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #f9f9f9;
    margin-right: 10px;
  }
}
@media screen and (max-width: 1199px) {
  .mobileMenuLogo {
    display: block;
    margin: 0 auto;
  }
}
.desktopMenuList {
  display: none;
}
@media screen and (min-width: 1200px) {
  .desktopMenuList {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .navDesktopLinks {
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    padding-top: 20px;
    margin-right: 40px;
  }
}
.activeLink {
  color: #e53d12 !important;
}
