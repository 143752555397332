.embla {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__container {
  position: relative;
  display: flex;
}

@media screen and ((max-width: 767px)) {
  .embla__slide {
    user-select: none;
    min-width: 100px;
    box-sizing: border-box;
    padding: 10px;
  }
}

@media screen and ((min-width: 768px)) {
  .embla__slide {
    user-select: none;
    min-width: 100px;
    box-sizing: border-box;
    padding: 14px 10px;
  }
}
.activeTag {
  white-space: nowrap;
  font-family: "Stolzl";
  padding: 14px 10px;
  background: #e53d12;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-right: 10px;
}
.tagsThumb {
  display: flex;
  margin-bottom: 40px;
}
.tagItem {
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  font-family: "Stolzl";
  padding: 14px 10px;
  background: var(--grey-bg, #f9f9f9);
  color: var(--txt-black, #1d1d1d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.tagItem:not(:last-child) {
  margin-right: 10px;
}
