@media screen and (max-width: 767px) {
  .notesTitle {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin-top: 60px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) {
  .notesTitle {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin-top: 80px;
    max-width: 690px;
    margin-bottom: 30px;
  }
}
.tagThumb {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.noteTags {
  white-space: nowrap;
  background-color: #f9f9f9;
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  padding: 14px 10px;
  margin-right: 20px;
}

.noteDate {
  color: var(--txt-grey, #c6c8d0);
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
@media screen and (min-width: 768px) {
  .noteThumb {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .noteImage {
    display: block;
    width: 100%;
    margin: 0 auto;
    height: 240px;
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 768px) {
  .noteImage {
    width: 310px;
    height: 240px;
    margin-right: 130px;
  }
}

.line {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .noteTitle {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.2px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) {
  .noteTitle {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 75px;
  }
}
.noteSubTitle {
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .notesList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) {
  .notesList {
    display: flex;
    margin-bottom: 60px;
    gap: 30px;
  }
}
@media screen and (min-width: 768px) {
  .noteTextLeftThumb,
  .noteTextRightThumb {
    width: calc(50% - 15px);
  }
}
@media screen and (max-width: 767px) {
  .noteTextLeft {
    color: #1d1d1d;
    font-kerning: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }

  .noteTextLeft:last-child {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .noteTextLeft {
    color: #1d1d1d;
    font-kerning: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    max-width: 360px;
  }
}
@media screen and (max-width: 767px) {
  .noteTextRight {
    color: #1d1d1d;
    font-kerning: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
}
@media screen and (min-width: 768px) {
  .noteTextRight {
    color: #1d1d1d;
    font-kerning: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    max-width: 360px;
  }
}
