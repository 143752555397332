.sectionTitle {
  color: #1d1d1d;

  font-family: "Firs Neue";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .booksAllThumb {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .booksAllThumb {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1200px) {
  .sectionTitle {
    color: #1d1d1d;

    font-family: "Firs Neue";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 55px */
  }
}

.booksPreviewTitle {
  color: var(--txt-black, #1d1d1d);
  text-align: right;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  margin-bottom: 20px;
}

.booksPreviewIcon {
  vertical-align: text-top;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .bookImage {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    background-color: var(--grey-bg, #f9f9f9);
  }
}

@media screen and (min-width: 768px) {
  .bookImage {
    display: block;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
    background-color: var(--grey-bg, #f9f9f9);
  }
}

@media screen and (max-width: 480px) {
  .bookThumb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 480px) {
  .bookThumb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .bookDescriptionThumb {
    display: flex;
    flex-direction: column;
    width: 40%;
  }
}

@media screen and (min-width: 768px) {
  .bookDescriptionThumb {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
}

.shoppingThumb {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .bookDescription {
    margin-right: 10px;
  }
}

@media screen and (min-width: 480px) {
  .bookDescription {
    /* width: 60%; */
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .bookShoppingCartThumb {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    height: 50px;
    background-color: #e53d12;
  }
}

@media screen and (min-width: 768px) {
  .bookShoppingCartThumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #e53d12;
  }
}

.bookPriceThumb {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 520px) {
  .bookPrice {
    margin-right: 20px;
    width: 80px;
  }
}

@media screen and (min-width: 480px) {
  .bookPrice {
    margin-right: 20px;
  }
}

.slider {
  margin-left: 10px;
  margin-bottom: 60px;
}

@media screen and (min-width: 768px) {
  .slider {
    display: none;
  }
}

.booksLine {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}

.booksPreviewList {
  display: none;
}

@media screen and (min-width: 768px) {
  .booksPreviewListMobile {
    display: none;
  }

  .booksPreviewList {
    display: flex;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 480px) {
  .bookItem {
    width: 100%; /* Full width for small screens */
    margin: 0 auto;
    margin-right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .bookItem {
    width: calc(33.333% - 20px);
    margin-right: 20px;
  }
}

.booksPreviewListMobile {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.sliderContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
} /* Improve slider container */

.slide {
  flex: 0 0 100%; /* Full width per slide */
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  padding: 0;
  margin: 0;
  list-style: none;
}

.dots {
  text-align: start;
  margin-top: 10px;
  margin-bottom: 60px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #f1f1f1;
  margin: 0 5px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
}

.dot.active {
  background-color: #e53d12;
}
