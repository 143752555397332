@media screen and (max-width: 1200px) {
  .sectionTitle {
    color: #1d1d1d;

    font-family: "Firs Neue";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .sectionTitle {
    color: var(--txt-black, #1d1d1d);
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin-bottom: 30px;
    width: 670px;
    margin-bottom: 60px;
  }
}
.authorInfoThumb {
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 1200px) {
  .authorDetailsThumb {
    width: 38%;
  }
}
@media screen and ((max-width: 479px)) {
  .authorThumb {
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin-bottom: 40px;
    margin-left: -60px;
  }
}
@media screen and ((min-width: 480px) and (max-width:1200px)) {
  .authorThumb {
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin-bottom: 40px;
    margin-left: -40px;
  }
}
@media screen and (min-width: 1200px) {
  .authorThumb {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-left: -14px;
    width: 60%;
  }
}
.authorBirthList {
  transform: rotate(-90deg);
  margin-top: 100px;
}
.authorBirth {
  color: var(--txt-grey, #c6c8d0);
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.authorDateOfBirth {
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}
.authorDescriptionThumb {
  display: flex;
}
@media screen and (max-width: 1200px) {
  .authorEmptySpace {
    width: 2vw;
  }
}
@media screen and (min-width: 1200px) {
  .authorEmptySpace {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .authorPhoto {
    margin-bottom: 37px;
  }
  .authorPhotoDesktop {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .authorPhoto {
    display: none;
  }
  .authorPhotoDesktop {
    margin-left: 20px;
  }
}
.authorName {
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media screen and (max-width: 1200px) {
  .authorDescription {
    width: 100%;
    color: #1d1d1d;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .authorDescription {
    width: 420px;
    color: #1d1d1d;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .descriptionThumb {
    margin-right: 30px;
    width: 45%;
  }
}
@media screen and (min-width: 1200px) {
  .textsThumb {
    width: 45%;
  }
}
.authorDetails {
  color: #1d1d1d;
  font-family: Stolzl;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-bottom: 60px;
}
.authorMoreIcon {
  margin-left: 10px;
  vertical-align: text-bottom;
}
.line {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}
