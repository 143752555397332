.footerList {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.footerOffer {
  color: #1d1d1d;
  font-family: "Firs Neue";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 12px */
  text-align: left;
  width: 50vw;
}
.footerLogo {
  margin: 0 20px;
}
.footerCopyrightThumb {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: end;
  color: #1d1d1d;
  font-family: "Firs Neue";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.footerCopyright {
  color: #1d1d1d;
  text-align: right;
  font-family: "Firs Neue";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.footerPoweredThumb {
  color: #1d1d1d;
  text-align: center;
  font-family: "Firs Neue";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 10px;
}
