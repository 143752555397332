.notesThumb {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 80px;
}

.notesTitle {
  width: 40vw;
  color: #1d1d1d;

  font-family: "Firs Neue";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 33px */
}
.notesDetails {
  color: #1d1d1d;
  text-align: right;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.notesMoreIcon {
  vertical-align: middle;
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .noteThumb {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .noteMobileThumb {
    display: none;
  }
}
.noteImg {
  margin-bottom: 14px;
  width: 100%;
}
.noteDetailsThumb {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.noteTags {
  background-color: #f9f9f9;
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  padding: 14px 10px;
  margin-right: 20px;
}
.noteDate {
  color: var(--txt-grey, #c6c8d0);
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
}
@media screen and (max-width: 767px) {
  .notesAuthor {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 22px */
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .notesAuthor {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 22px */
    margin-bottom: 20px;
  }
}
.notesSlider {
  margin-bottom: 60px;
}
.line {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 60px;
}
.noteDesktopThumb {
  display: none;
}
@media screen and (min-width: 768px) {
  .noteDesktopThumb {
    display: block;
  }
  .notesList {
    display: flex;
    width: 100%;
    margin-bottom: 60px;
  }
  .noteItem {
    width: calc((100% / 3) - 20px);
    display: flex;
    flex-direction: column;
  }
  .noteItem:not(:last-child) {
    margin-right: 20px;
  }
}
.notesAuthorText {
  width: 300px;
}
.tagThumb {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.noteMobileThumb {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.sliderContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  padding: 0;
  margin: 0;
  list-style: none;
}

.slide {
  min-width: 100%;
  box-sizing: border-box;
}

.dots {
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 60px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #f1f1f1;
  margin: 0 5px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
}

.dot.active {
  background-color: #e53d12;
}
