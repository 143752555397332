.heroSectionThumb {
  position: relative;
}

.heroThumb {
  margin-bottom: 40px;
}

@media screen and (max-width: 480px) {
  .heroImgMobile {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 90%;
    width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .heroImgMobile {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 70%;
    width: 100%;
  }
}

/* @media screen and (min-width: 767px) and (max-width: 1200px) {
  .heroImgMobile {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 90%;
    width: 92vw;
  }
} */
.heroImgDesktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .heroImgMobile {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .heroImgDesktop {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 80%;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .heroSignatureIconThumb {
    position: absolute;
    /* top: 54%; */
    transform: translate(-100%, -104%);
    left: 100%;
  }
}
@media screen and (max-width: 767px) {
  .heroSignatureIcon {
    width: 60px;
  }
}
@media screen and (min-width: 768px) {
  .heroSignatureIconThumb {
    position: absolute;
    /* top: 54%; */
    transform: translate(-97%, -110%);
    left: 97%;
  }
}
@media screen and ((min-width: 768px) and (max-width:1199px)) {
  .heroSignatureIcon {
    width: 81px;
  }
}
@media screen and (min-width: 1200px) {
  .heroSignatureIcon {
    width: 100px;
  }
}
@media screen and (max-width: 768px) {
  .heroDesktopThumb {
    height: 220px;
    padding: 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .heroDesktopThumb {
    height: 200px;
    padding: 0px;
  }
}
@media screen and (min-width: 768px) {
  .heroDesktopThumb {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
  }
}

.heroLine {
  width: 420px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}
.heroLineShort {
  width: 420px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .bookThumb {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .bookTitle {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 55px */
  }
}
@media screen and ((min-width: 768px) and (max-width: 1199px)) {
  .bookTitle {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 55px */
    margin-right: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .bookTitle {
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 55px */
    max-width: 640px;
    margin-right: 140px;
  }
}
@media screen and (max-width: 767px) {
  .bookBtn {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 62px;
    background-color: #e53d12;
    color: #fff;
    border: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-top: 40px;
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 768px) {
  .bookBtn {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 62px;
    background-color: #e53d12;
    color: #fff;
    border: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-top: 40px;
    margin-bottom: 134px;
  }
}

@media screen and (max-width: 767px) {
  .bookDescription {
    color: #1d1d1d;
    font-kerning: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-bottom: 60px;
  }
}
.arrowIcon {
  margin-left: 5px;
  vertical-align: middle;
}
@media screen and ((min-width: 768px) and (max-width: 1199px)) {
  .bookDescription {
    color: #1d1d1d;
    font-kerning: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .bookDescription {
    color: #1d1d1d;
    font-kerning: none;
    font-family: "Stolzl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-bottom: 80px;
    max-width: 420px;
  }
}
