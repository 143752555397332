@media screen and (max-width: 767px) {
  .coming_soon {
    margin-top: 45vh;
    font-family: "Firs Neue", sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 768px) {
  .coming_soon {
    margin-top: 45vh;
    font-family: "Firs Neue", sans-serif;
    font-size: 80px;
    font-weight: 400;
    line-height: 80px;
    text-align: center;
    text-transform: uppercase;
  }
}
