@font-face {
  font-family: "Firs Neue";
  src: url("./fonts/PlayfairDisplay-Regular.woff");
}
@font-face {
  font-family: "Stolzl";
  src: url("./fonts/Lora-Regular.woff");
}
body,
html {
  box-sizing: border-box;
  /* background-color: #f5efe6;
  background-image: url("./images/wrapped-paper.jpg"); */
  /* background-size: cover; */
}
@media screen and (max-width: 1200px) {
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  section {
    padding: 0px 20px;
  }
}
@media screen and (min-width: 1200px) {
  body {
    padding: 0 70px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  margin: 0;
}
ul,
li,
a {
  text-decoration: none;
  color: #000;
  list-style: none;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}
:root {
  --accent-color: #d81e05;
  --secondary-color: #2b1b17;
  --background-color: #f5efe6;
  --text-color: #3a2f2f;
}
