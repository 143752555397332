.sectionTitle {
  color: #1d1d1d;

  font-family: "Firs Neue";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 20px;
  margin-top: 60px;
}

@media screen and (min-width: 1200px) {
  .sectionTitle {
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin-top: 80px;
  }
}

.booksPreviewTitle {
  color: var(--txt-black, #1d1d1d);
  text-align: right;
  font-family: Stolzl;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  margin-bottom: 20px;
}

.booksPreviewIcon {
  vertical-align: text-top;
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .bookImage {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    background-color: var(--grey-bg, #f9f9f9);
  }
}

@media screen and (min-width: 1200px) {
  .bookImage {
    display: block;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
    background-color: var(--grey-bg, #f9f9f9);
  }
}

@media screen and (max-width: 480px) {
  .bookThumb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 480px) {
  .bookThumb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .bookDescriptionThumb {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
}

@media screen and (min-width: 768px) {
  .bookDescriptionThumb {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
}

.shoppingThumb {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .bookDescription {
    margin-right: 10px;
  }
}

@media screen and (min-width: 480px) {
  .bookDescription {
    /* width: 60%; */
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .bookShoppingCartThumb {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    height: 50px;
    background-color: #e53d12;
  }
}

@media screen and (min-width: 768px) {
  .bookShoppingCartThumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #e53d12;
  }
}

.bookPriceThumb {
  display: flex;
  align-items: center;
}

.bookPrice {
  /* margin-right: 20px; */
  width: 80px;
}

@media screen and (min-width: 480px) {
  .bookPrice {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .bookItem:not(:last-child) {
    margin-bottom: 20px;
  }
  .bookItem:last-child {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) {
  .bookItem {
    margin-right: 20px;
    margin-bottom: 20px;
    width: calc((100% - 60px) / 3);
  }
}

.booksLine {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}

.booksPreviewList {
  display: none;
}
@media screen and (max-width: 767px) {
  .booksMobilePreviewList {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .booksMobilePreviewList {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 768px) {
  .booksPreviewList {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .comingSoonTitle {
    margin-bottom: 30px;
    text-align: center;
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 768px) {
  .comingSoonTitle {
    margin-bottom: 40px;
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 767px) {
  .comingSoonItemTitle {
    margin-bottom: 10px;
    width: 50%;
    color: #e53d12;
    font-family: "Firs Neue";
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 768px) {
  .comingSoonItemTitle {
    margin-right: 60px;
    width: calc((100% - 60px) / 2);
    width: 40%;
    margin-bottom: 10px;
    color: #e53d12;
    font-family: "Firs Neue";
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
  }
}
.comingSoonThumb {
  display: flex;
}
@media screen and (max-width: 767px) {
  .emptyBlock {
    width: 40%;
  }
}
@media screen and (max-width: 768px) {
  .comingSoonItemText {
    /* width: 53%; */
    color: var(--txt-black, #1d1d1d);
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .comingSoonItemText {
    /* width: 53%; */

    color: var(--txt-black, #1d1d1d);
    font-family: "Stolzl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 20px;
  }
}
.comingSoonItem:last-child {
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .comingSoonList {
    display: flex;
  }
  .comingSoonItem {
    margin-right: 20px;
    width: calc((100% - 20px) / 2);
  }
}
@media screen and (min-width: 768px) {
  .comingSoonBookThumb {
    display: flex;
    margin-bottom: 80px;
  }
}
