.noticesSection {
  padding: 0px;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  .notesTitle {
    padding-top: 54px;
    margin-bottom: 20px;
    color: #1d1d1d;
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
}
@media screen and (min-width: 768px) {
  .notesTitle {
    padding-top: 80px;
    margin-bottom: 20px;
    color: #1d1d1d;
    color: var(--txt-black, #1d1d1d);
    font-family: "Firs Neue";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
  }
}

.notesDetails {
  color: #1d1d1d;
  text-align: right;
  font-family: "Stolzl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6;
}
.notesMoreIcon {
  vertical-align: middle;
  margin-left: 10px;
}

.noteImg {
  margin-bottom: 14px;
  width: 100%;
}
.noteDetailsThumb {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.tagThumb {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.noteTags {
  white-space: nowrap;
  background-color: #f9f9f9;
  color: #1d1d1d;
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  padding: 14px 10px;
  margin-right: 20px;
}

.noteDate {
  color: var(--txt-grey, #c6c8d0);
  font-family: "Stolzl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
@media screen and ((max-width: 767px)) {
  .notesAuthor {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
  }
}
@media screen and ((min-width: 768px) and (max-width: 1200px)) {
  .notesAuthor {
    display: flex;
    align-items: flex-end;
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 22px */
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .notesAuthor {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #1d1d1d;
    font-family: "Firs Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
  }
}
.notesSlider {
  margin-bottom: 60px;
}
.line {
  width: 335px;
  height: 1px;
  background-color: #000;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 60px;
}

@media screen and (min-width: 768px) {
  .notesThumb {
    display: block;
  }

  .noteItem {
    width: calc((100% / 3) - 20px);
    display: flex;
    flex-direction: column;
  }
  .noteItem:not(:last-child) {
    margin-right: 20px;
  }
}
.notesThumb {
  margin-right: 20px;
}
.notesList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 60px;
}

.notesAuthorText {
  /* width: 300px; */
  color: var(--txt-black, #1d1d1d);
  font-family: "Firs Neue";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}
